import * as React from 'react';
import styled from 'styled-components';
import {StaticQuery, graphql} from "gatsby";
import {mq, pageGutters} from "../common/styles";
import Gallery from '@browniebroke/gatsby-image-gallery'
import {useState} from "react";
import { colors } from "../common/styles";

const sectionImagesQuery = graphql`
query  {
  allFile(
    sort: {fields: relativePath},
    filter: {absolutePath:{regex:"/images/sections/"}}
  ) {
    nodes {
      relativePath 
      childImageSharp {
        fluid(quality:90) {
        ...GatsbyImageSharpFluid
        }
              thumb: fluid(maxWidth: 800, maxHeight: 800) {
                ...GatsbyImageSharpFluid
              }
              full: fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
      }
    }
  }
}`;


const Container = styled.div`
  ${mq.laptop} {
    margin: 8px ${pageGutters.laptop}px;
  }
 
  .button-container {
    display: flex;
    justify-content: center;
  } 
  button {
    outline: none;
    background: none;
    border-radius: 12px;
    border: 1px solid ${colors.ivy};
    color: ${colors.ivy};
    transition: 0.15s;
    padding: 6px 8px;
    &:hover {
      background: ${colors.ivylight};
      color: ${colors.gunmetal};
      border-color: ${colors.gunmetal};
    }
  }
`;


// sectionName is the folder in images/sections/<sectionName>
const SectionImages = ({sectionName=' '}) => {
    const [showingAll, setShowingAll] = useState(false);

    const reg = new RegExp(`sections/${sectionName}`);

    const getImages = (data) => {
        return data.allFile.nodes
            .filter(node => !!node.relativePath.match(reg))
            .map(node => node.childImageSharp);
    };

    return (
        <Container>
            <StaticQuery
                query={sectionImagesQuery}
                render={(data) => {
                    const maxImages = 3;
                    const images = getImages(data);
                    const visibleImages = showingAll ? images : images.slice(0, maxImages);
                    const showButton = images.length > maxImages;

                    return (
                        <>
                            <Gallery images={visibleImages} mdColWidth={33.33} />
                            {showButton && (
                                <div className='button-container'>
                                    <button
                                        onClick={() => setShowingAll(!showingAll)}
                                    >
                                        Show {showingAll ? 'less' : 'more'} pics
                                    </button>
                                </div>
                            )}
                        </>
                    );
                }}
            />
        </Container>
    );
};
export default SectionImages
