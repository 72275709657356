import * as React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import {colors} from "../common/styles";

const StyledCallToAction = styled.div`
  text-align: center;
  margin: 16px 0;
  .primer {
	  color: ${colors.gunmetallight};
	  font-size: 11px;
	  font-weight: 300;
  }
  .button {
  	  display: inline-block;
	  background-color: ${colors.ivy};
	  color: white;
	  border: none;
	  border-radius: 12px;
	  font-size: 16px;
	  padding: 12px 24px;
	  transition: .15s ease;
	  &:hover {
	  	color: white;
		background-color: ${colors.ivylight};
	  }
  }
`;

function CallToAction(props) {
	const {
		to,
		primerText,
		actionText = '',
		emoji = ''
	} = props;

	return (
		<StyledCallToAction>
			{primerText && <div className='primer'>{primerText}</div>}
			<Link to={to} className='button'>
				{`${actionText} ${emoji}`}
			</Link>
		</StyledCallToAction>
	);

}

export default CallToAction;

