import * as React from 'react';
import styled from 'styled-components';
import Img from "gatsby-image";
import PageContentSections from '../components/PageContentSections';
import PageContent from '../components/PageContent';
import PageGutters from '../components/PageGutters';
import Layout from '../components/Layout';
import CallToAction from "../components/CallToAction";
import Disclaimer from '../components/Disclaimer';
import {colors} from "../common/styles";
import { PackageContainer, Package } from "../components/packages";
import {graphql} from "gatsby";
import { SubNavHeaderIds } from "../common/config";
import SectionImages from '../components/SectionImages';

const AlaCarteGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .item {
    width: 200px;
    margin: 8px;
    padding: 8px;
    //border: 1px solid rgba(0,0,0,0.05);
    //background: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .name {
    font-size:12px;
    font-weight: 600;
    color: ${colors.gunmetal};
  }
  .price {
    color: ${colors.gunmetallight};
  }
  .pic {
    margin: 4px 0 ;
    width: 120px;
  }
`;


export function RentalsPage({ data, showPrices=false}) {
  const rentals = data.allMarkdownRemark.edges.map(edge => edge.node.frontmatter);

  const contents = [{
    header: 'Miss Daisy',
    headerId: SubNavHeaderIds.missDaisy,
    SectionContent: () => (
      <div>
        <PageGutters>
          <p>
            Miss Daisy is Ivory & Rust’s 1994 miniature horse trailer turned food and beverage cart and mobile market. As a miniature trailer, Miss Daisy is the perfect fit for both indoor and outdoor events. Miss Daisy is a “dry hire,” meaning all products, food/beverage, alcohol, etc. to be displayed in Miss Daisy must be provided by the client.
          </p>
        </PageGutters>
        <SectionImages sectionName='miss-daisy' />
        <PageGutters>
          <p>
            Catch Miss Daisy this Spring popping up at parks, community events, weddings, parking lots, and more. Parties big or small, Miss Daisy will host it all! From locally-grown produce and hand-cut flowers, to vintage and boutique clothing and jewelry from local vendors, to sips and bites of all kinds, Miss Daisy is ready to be the perfect addition to your special event. Are you the hostess with the mostest? Use Miss Daisy to throw an unforgettable experience complete with kegs, cocktails, or a full whiskey bar. Are you someone who likes to keep it casual? Stock Miss Daisy with coffee, cocoa, or cookies for a more intimate, family-friendly gathering. Do you like to keep it classy? Pinkies up! Miss Daisy can serve all the tea and macaroons or wine and cheese for the ultimate girls’ night. The possibilities are endless!
            As if that wasn’t enough to make you love Miss Daisy, booking her includes a photo backdrop that is sure to wow your guests and create lasting memories for you to share for years to come.
          </p>
          <p>
              Rent Miss Daisy for your next event! See packages below.
          </p>
        </PageGutters>
          <PackageContainer>
              <Package
                  name='The Sip Sip Hooray'
                  price='$400'
                  showPrice={showPrices}
                  details={[
                      '4 Hours Serving Time',
                      '1 Tender',
                      'Watering Station',
                      'Coordinated Décor',
                      'Set up & tear down',
                      '1 Round, High-Top Pub Table',
                      '2 Metal bar stools',
                  ]}
              />
              <Package
                  name='The Roadie'
                  price='$600'
                  showPrice={showPrices}
                  details={[
                      '6 Hours Serving Time',
                      '2 Tenders',
                      'Watering Station',
                      'Coordinated Décor',
                      'Set up & tear down',
                      '2 Round, High-Top Pub Tables',
                      '4 Metal bar stools',
                  ]}
              />
              <Package
                  name='The Long Haul'
                  price='$800'
                  showPrice={showPrices}
                  details={[
                      '8 hours serving time',
                      '2 Tenders',
                      'Watering Station',
                      'Coordinated Décor',
                      'Outdoor Lounge',
                      'Set up & tear down',
                      '2 Round, High-Top Pub Tables',
                      '4 Metal bar stools',
                  ]}
              />
          </PackageContainer>
          <CallToAction
              to='/contact'
              actionText='Rent Miss Daisy'
              primerText={`Wanna take Miss Daisy for a spin?`}
          />
      </div>
    )
  }, {
    header: 'A la Carte',
    headerId: SubNavHeaderIds.aLaCarte,
    SectionContent: () => (
        <>
        <PageGutters>
          <p>
            When you use Ivory & Rust services, we have an extensive stock of items to make your event event more meaningful. Let us know
            if you need anything specific and we'll work with you the best we can.
            <div style={{fontSize: '0.8rem', fontWeight: 200, marginTop: 4, color: colors.gunmetallight}}>Sample of some of our items below</div>
          </p>
        </PageGutters>
        <AlaCarteGrid>
            {rentals.map((rental, i) => (
                <div className='item' key={i}>
                    <div className='name'>{rental.name}</div>
                    <div className='pic'>
                        {rental.featuredImage && <Img fluid={rental.featuredImage.childImageSharp.fluid} />}
                    </div>
                    {showPrices && <div className='price'>{rental.price}</div>}
                </div>
            ))}
        </AlaCarteGrid>
        <CallToAction
          to='/contact'
          actionText='Contact Me'
          primerText='For all rentals inquiries...'
        />
      </>
    )
  }];

  return (
    <Layout subPageTitle='Rentals'>
      <PageContentSections contents={contents} />
      <PageContent>
          <Disclaimer/>
      </PageContent>
    </Layout>
  );
}

export const query = graphql`
query {
  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/rentals/"}}, sort: {fields: frontmatter___order, order: ASC}) {
    edges {
      node {
        frontmatter {
          name
          price
          featuredImage {
           childImageSharp {
            fluid(quality:90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}
}
`;

export default RentalsPage;
