import * as React from 'react';
import styled from 'styled-components';
import { mq, pageGutters } from '../common/styles';

const StyledPageContent = styled.div`
  padding: 0 ${pageGutters.mobileS}px;
  ${mq.mobileL} {
    padding: 0 ${pageGutters.mobileL}px;
  }
  ${mq.laptop} {
   padding: 0 ${pageGutters.laptop}px;
  }
`;

export default StyledPageContent;

