import * as React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  font-size: 10px;
  opacity: 0.75;
  letter-spacing: 1px;
`;

export default function Disclaimer() {
	return (
		<Styled>
			* All services and rentals subject to availability and prices subject to change
		</Styled>
	);
}
