import * as React from 'react';
import styled from 'styled-components';
import {mq, fonts, colors} from "../common/styles";
import {string} from "prop-types";

export const PackageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;
  ${mq.laptop} {
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

const StyledPackage = styled.div`
    margin: 4px 8px;
    padding: 12px 8px;
    border-radius: 4px;
    .package-header {
      display: flex;
      align-items: center;
    }
	.package-name {
      font-weight: 700;
      font-family: ${fonts.nothingYouCouldDo};
      font-size: 18px;
      letter-spacing: -0.8px;
      color: ${colors.rust}
	}
	.package-price {
      font-weight: 500;
      letter-spacing: -0.5px;
      font-size: 11px;
      color: ${colors.gunmetal};
	}
	.sep {
  	  opacity: 0.5;
      margin-left: 8px;
      margin-right: 4px;
  	  color: ${colors.gunmetallight};
	}
	.package-details {
      margin: 0 0 0 16px;
      padding: 0 0 0 0;
      font-size: 12px;
      li {
        line-height: 1.4em;
      }
	}
`;


export function Package({name, price, details, showPrice}) {

  if (typeof details === 'string') {
    details = details.trim().split('\n').map(l => l.trim())
  }

  return (
      <StyledPackage>
        <div className='package-header'>
          <div className='package-name'>{name}</div>
          {showPrice && (
            <>
              <div className='sep'>|</div>
              <div className='package-price'>{price}</div>
            </>
          )}
        </div>
        <ul className='package-details'>
          {details.map(d => (
            <li key={d}>{d}</li>
          ))}
        </ul>
	  </StyledPackage>
  )
}
