import * as React from 'react';
import styled from 'styled-components';
import PageGutters from './PageGutters';

const ContentSection = styled.section`
  .header {
    padding-top: 16px; 
  }
  .content {
  
  }
`;

type Content = {
  header: string;
  headerId: string;
  SectionContent: React.FunctionComponent;
}
type Props = {
  contents: Content[];
}

export default function PageContentSections(props:Props) {
  const {
    contents,
  } = props;

  return (
    <>
    {contents.map(({ header, headerId, SectionContent }) => (
        <ContentSection key={headerId}>
          <PageGutters><h2 className='header' id={headerId}>{header}</h2></PageGutters>
          <div className='content'>
            {SectionContent && <SectionContent />}
          </div>
        </ContentSection>
    ))}
    </>
  );
}
